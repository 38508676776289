<template>
  <input
    ref="autocomplete"
    type="text"
    :class="classname"
    :id="id"
    :placeholder="placeholder"
    :value="value"
    @input="$emit('input', $event.target.value)"
  />
</template>

<script>
  const ADDRESS_COMPONENTS = {
    subpremise : 'short_name',
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    administrative_area_level_2: 'long_name',
    country: 'long_name',
    postal_code: 'short_name'
  };
  const CITIES_TYPE = ['locality', 'administrative_area_level_3'];
  const REGIONS_TYPE = ['locality', 'sublocality', 'postal_code', 'country',
      'administrative_area_level_1', 'administrative_area_level_2'];

  export default {
    name: 'LocationAutocomplete',
    props: {
      value:String,
      id: {
        type: String,
        required: true
      },
      classname: String,
      placeholder: {
        type: String,
        default: 'Start typing'
      },
      types: {
        type: String,
        default: ''
      },
      country: {
        type: [String, Array],
        default: null
      }
    },
    data() {
      return {
        /**
         * The Autocomplete object.
         *
         * @type {Autocomplete}
         * @link https://developers.google.com/maps/documentation/javascript/reference#Autocomplete
         */
        autocomplete: null,
      }
    },
    watch: {
      country: function(newVal, oldVal) {
        this.autocomplete.setComponentRestrictions({
          country: this.country === null ? [] : this.country
        });
      }
    },
    mounted: function() {
      const options = {};
      if (this.types) {
        options.types = [this.types];
      }
      if (this.country) {
        options.componentRestrictions = {
          country: this.country
        };
      }
      this.autocomplete = new google.maps.places.Autocomplete(
          (this.$refs.autocomplete),
          options
      );
      this.autocomplete.addListener('place_changed', this.onPlaceChanged);
    },
    methods: {
      /**
       * When a place changed
       */
      onPlaceChanged(event) {
        let place = this.autocomplete.getPlace();
        if (!place.geometry) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          this.$emit('no-results-found', place, this.id);
          return;
        }
        if (place.address_components !== undefined) {
          // return returnData object and PlaceResult object
          this.$emit('placechanged', this.formatResult(place), place, this.id);
          this.$emit('input', place.formatted_address)
        }
      },
      /**
       * Format result from Geo google APIs
       * @param place
       * @returns {{formatted output}}
       */
      formatResult (place) {
        let returnData = {};
        for (let i = 0; i < place.address_components.length; i++) {
          let addressType = place.address_components[i].types[0];
          if (ADDRESS_COMPONENTS[addressType]) {
            let val = place.address_components[i][ADDRESS_COMPONENTS[addressType]];
            returnData[addressType] = val;
          }
        }
        returnData['latitude'] = place.geometry.location.lat();
        returnData['longitude'] = place.geometry.location.lng();
        return returnData
      }
    }
  }
</script>
