<template>
  <div class="cta-form-wrapper" :class="{'cta-form-wrapper--collapsed':useCollapsedView}">
    <section class="cta-form" :class="{'cta-form--collapsed':useCollapsedView, 'cta-form--expanded':!useCollapsedView, 'hidden':hide}">

      <div class="cta-form__close-button" @click="toggleMobileForm"><i class="fas fa-times px-3 py-4 text-2xl"></i></div>

      <div class="cta-form__content" >
        <h1 class="cta-form__title">
          <span @click="toggleMobileForm">{{ titleText }}</span>
        </h1>
        <form @keydown.enter.prevent class="md:px-4">
          <div class="mb-2 relative">
            <location-input id="fromAddress" label="Umzug von" :label-color="'text-black'" v-model="fromLocation"/>
          </div>
          <div class="mb-2">
            <location-input id="toAddress" label="nach" :label-color="'text-black'" v-model="toLocation" />
          </div>
          <input id="cta.next" type="submit" value="Kosten schätzen" class="rounded py-1 px-8 inline-block bg-black text-white cursor-pointer mb-2" @click.prevent="startWizard" @keydown.enter.prevent="startWizard">
        </form>
      </div>
    </section>
  </div>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  import LocationInput from './components/LocationInput.vue'

  export default {
    components: {
        LocationInput
    },
    props: {
      scrolled: {
        type: Boolean,
        default: false
      },
      initiallyExpanded: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        fromLocation: {
          address: '',
          latitude: 0,
          longitude: 0
        },
        toLocation: {
          address: '',
          latitude: 0,
          longitude: 0
        },
        isMobileView: false,
        isCollapsed: false,
        hide: false,
        currentViewportWidth: 0
      }
    },
    computed: {
      titleText() {
        if(this.useCollapsedView) {
          return 'Umzugsofferte erhalten'
        } else {
          return 'Erhalte kostenlose Offerten von Umzugshelden in deiner Region'
        }
      },
      useCollapsedView() {
        if(this.isMobileView) {
          return this.isCollapsed
        } else {
          return this.isCollapsed

        }
      }
    },
    methods: {
      startWizard(event) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'ActionTracking',
          'trackedAction': 'cta.next',
          'pagePath': document.location.protocol + '//' +
                      document.location.hostname +
                      document.location.pathname +
                      document.location.search,
          'pageTitle': document.title
        });

        this.$root.$emit('start-wizard', {'fromLocation': this.fromLocation, 'toLocation': this.toLocation})
        this.hide = true
        if(this.isMobileView) {
          this.isCollapsed = true
        }
      },
      toggleMobileForm() {
        this.isCollapsed = !this.isCollapsed
      },
      handleViewportResize() {
        // only react to width changes
        if(window.innerWidth != this.currentViewportWidth) {
          if(window.innerWidth < 1400) {
            this.isMobileView = true
            this.isCollapsed = true
          } else {
            this.isMobileView = false
            if(this.initiallyExpanded) {
              this.isCollapsed = this.scrolled
            }
          }
        }
      }
    },
    watch: {
      scrolled: {
        handler() {
          if(!this.isMobileView && this.initiallyExpanded) {
            this.isCollapsed = this.scrolled
          }

        }
      }
    },
    mounted() {
      this.$root.$on('wizard-closed', () => {
        this.hide = false
      })
      if (localStorage.getItem('inquiry')) {
        this.hide = true
      }
    },
    beforeMount() {
      if(window.innerWidth < 1400) {
        this.isMobileView = true
        this.isCollapsed = true
      } else {
        this.isCollapsed = !this.initiallyExpanded
      }

      this.currentViewportWidth = window.innerWidth

      window.addEventListener('resize', this.handleViewportResize)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleViewportResize)
    },
  }
</script>
