import { render, staticRenderFns } from "./LocationAutocomplete.vue?vue&type=template&id=2d3bfb38&"
import script from "./LocationAutocomplete.vue?vue&type=script&lang=js&"
export * from "./LocationAutocomplete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vagrant/sites/AH Digital/deinehelden.ch/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('2d3bfb38', component.options)
    } else {
      api.reload('2d3bfb38', component.options)
    }
    module.hot.accept("./LocationAutocomplete.vue?vue&type=template&id=2d3bfb38&", function () {
      api.rerender('2d3bfb38', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/components/LocationAutocomplete.vue"
export default component.exports