var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "label",
        {
          staticClass: "block text-lg text-center",
          class: _vm.labelColor,
          attrs: { for: _vm.id }
        },
        [_vm._v(_vm._s(_vm.label))]
      ),
      _vm._v(" "),
      _c("location-autocomplete", {
        class: { "border-error": _vm.error },
        attrs: {
          id: _vm.id,
          autofocus: _vm.autofocus,
          classname: "block w-full p-1 bg-gray rounded text-center",
          placeholder: "Adresse, PLZ oder Ort",
          country: "ch"
        },
        on: { placechanged: _vm.placeChangedHandler },
        model: {
          value: _vm.value.address,
          callback: function($$v) {
            _vm.$set(_vm.value, "address", $$v)
          },
          expression: "value.address"
        }
      }),
      _vm._v(" "),
      _vm.error
        ? _c("span", { staticClass: "text-sm text-error" }, [
            _vm._v(_vm._s(_vm.error))
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }