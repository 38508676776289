<template>
  <div>
    <label :for="id" class="block text-lg text-center" :class="labelColor">{{ label }}</label>
    <location-autocomplete
      :id="id"
      :autofocus="autofocus"
      classname="block w-full p-1 bg-gray rounded text-center"
      :class="{ 'border-error': error }"
      placeholder="Adresse, PLZ oder Ort"
      v-model="value.address"
      :country="'ch'"
      @placechanged="placeChangedHandler">
    </location-autocomplete>
    <span v-if="error" class="text-sm text-error">{{error}}</span>
  </div>
</template>

<script>
  import LocationAutocomplete from './LocationAutocomplete.vue'

  export default {
    name: 'LocationInput',
    components: {
      LocationAutocomplete
    },
    props: {
      value: Object,
      error: String,
      label: {
        type: String,
        required: true
      },
      labelColor: {
        type: String,
        default: 'text-primary'
      },
      id: {
        type: String,
        required: true
      },
      autofocus: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      placeChangedHandler(data) {
        this.$emit('placechanged', data)
        this.value.canton = data.administrative_area_level_1
        this.setLatLng(data.latitude, data.longitude)
      },
      setLatLng(latitude, longitude) {
        this.value.latitude = latitude
        this.value.longitude = longitude
      }
    }
  }
</script>
